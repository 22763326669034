import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { getDocument } from '@change-corgi/core/window';

import { isSafari } from 'src/app/shared/utils/userAgent';

export type SupportedMimeType = 'video/mp4; codecs="avc1.424028, mp4a.40.2"' | 'video/webm;codecs="vp8,opus"';

/**
 * Replicating https://www.webmproject.org/detect where `canPlayType` is taken as a yes if
 * response is "probably" or "maybe", and no if ""
 */
function safeCanPlayType(mimeType: string) {
	try {
		const video = getDocument().createElement('video');
		return video.canPlayType(mimeType);
	} catch {
		return '';
	}
}

export function useSupportedMimeType(): SupportedMimeType {
	const utilityContext = useUtilityContext();
	const mimeType =
		safeCanPlayType('video/webm') === ''
			? 'video/mp4; codecs="avc1.424028, mp4a.40.2"'
			: 'video/webm;codecs="vp8,opus"';

	// This handles current flakey canPlayType for Mac Safari, which returns 'maybe' even when not supported.
	if (isSafari(utilityContext)) return 'video/mp4; codecs="avc1.424028, mp4a.40.2"';
	return mimeType;
}

export function useCanPlayWebm(): boolean {
	const utilityContext = useUtilityContext();

	const canPlayWebm = safeCanPlayType('video/webm');

	// Webm is not supported
	if (canPlayWebm === '') {
		return false;
	}

	// This handles current flakey canPlayType for Mac Safari, which returns 'maybe' even when not supported.
	if (isSafari(utilityContext)) {
		return false;
	}

	return true;
}
