import type { ComponentProps } from 'react';

import { Link, Text } from '@change/tomato-core-ui';
import {
	FooterLayout,
	FooterLayoutBottom,
	FooterLayoutCopyright,
	FooterLayoutLanguageSwitcher,
	FooterLayoutSection,
	FooterLayoutSeoContent,
} from '@change/tomato-layout';

import { getHrefLang } from '@change-corgi/config/locales';
import { Translate, TranslateHtml, useI18n } from '@change-corgi/core/react/i18n';

import { useFooter } from './hooks/useFooter';

export const Footer: React.FC<ComponentProps<'div'>> = (props) => {
	const {
		data: { links, currentLocale, locales, currentYear },
		actions: { handleLocaleChange },
	} = useFooter();
	const { translate } = useI18n();

	return (
		<FooterLayout {...props}>
			{links.map((linkGroup) => (
				<FooterLayoutSection key={linkGroup.title} title={linkGroup.title}>
					{linkGroup.links.map((link) => (
						<Link key={link.title} href={link.href} target={link.target} variant="primary" underline={false}>
							{link.title}
						</Link>
					))}
				</FooterLayoutSection>
			))}
			<FooterLayoutBottom>
				<FooterLayoutLanguageSwitcher
					defaultLanguage={currentLocale}
					languages={locales}
					onChange={handleLocaleChange}
					ariaLabel={translate('fe.components.footer.locale')}
				/>
				<FooterLayoutSeoContent>
					<noscript>
						<ul>
							{locales.map((locale) => {
								const hrefLang = getHrefLang(locale.value);

								return (
									<li key={locale.label}>
										<Link href={`https://www.change.org/${hrefLang}`}>{locale.label}</Link>
									</li>
								);
							})}
						</ul>
					</noscript>
				</FooterLayoutSeoContent>
				<FooterLayoutCopyright>
					<Text fontWeight="bold" size="small" className="text-content-primary">
						<Translate value="fe.components.footer.copyright" replacements={{ year: currentYear }} />
					</Text>
					<Text size="small" className="text-content-primary">
						<TranslateHtml
							value="fe.components.footer.recaptcha.branding_text"
							replacements={{
								googlePrivacyPolicyLink: (
									<Link href="https://policies.google.com/privacy" target="_blank">
										<Translate value="fe.components.footer.recaptcha.privacy_policy_link_text" />
									</Link>
								),
								googleToSLink: (
									<Link href="https://policies.google.com/terms" target="_blank">
										<Translate value="fe.components.footer.recaptcha.terms_of_service_link_text" />
									</Link>
								),
							}}
						/>
					</Text>
				</FooterLayoutCopyright>
			</FooterLayoutBottom>
		</FooterLayout>
	);
};
