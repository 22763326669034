import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { isDecisionMaker } from './decisionMaker';
import { isGdprConsentProvided } from './gdprConsentProvided';
import { isPolicyAbuseExperimentEnabled } from './isPolicyAbuseExperimentEnabled';
import { isSignatureThermometerEnabled } from './isSignatureThermometerEnabled';
import { isStickySignatureBlockExperimentEnabled } from './isStickySignatureBlockExperimentEnabled';

export type PetitionDetailsPageUserData = {
	decisionMaker: boolean;
	gdprConsentProvided: boolean;
	policyAbuseExperimentEnabled: boolean;
	signatureThermometerEnabled: boolean;
	stickySignatureBlockExperimentEnabled: boolean;
};

export async function getPetitionDetailsPageUserData(
	utilityContext: UtilityContext,
	slugOrId: string,
	{ decisionMakerHash, isGuest }: { decisionMakerHash?: string; isGuest: boolean },
): Promise<PetitionDetailsPageUserData> {
	const [
		decisionMaker,
		gdprConsentProvided,
		policyAbuseExperimentEnabled,
		signatureThermometerEnabled,
		stickySignatureBlockExperimentEnabled,
	] = await Promise.all([
		!isGuest || decisionMakerHash ? isDecisionMaker(utilityContext, { slugOrId, decisionMakerHash }) : false,
		isGdprConsentProvided(utilityContext),
		isPolicyAbuseExperimentEnabled(utilityContext),
		isSignatureThermometerEnabled(utilityContext),
		isStickySignatureBlockExperimentEnabled(utilityContext),
	]);

	return {
		decisionMaker,
		gdprConsentProvided,
		policyAbuseExperimentEnabled,
		signatureThermometerEnabled,
		stickySignatureBlockExperimentEnabled,
	};
}
