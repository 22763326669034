import type { ApiPetitionUpdate } from './types';

export function normalizeMedia(update: ApiPetitionUpdate): Readonly<{ url: string; processing: boolean }> | null {
	if (update.__typename === 'MilestonePetitionUpdate') return null;
	if (update.__typename === 'FeaturedNewsPetitionUpdate')
		return update.newsSource.photo
			? {
					url: update.newsSource.photo.url,
					processing: false,
				}
			: null;
	if (update.photo)
		return {
			url: update.photo.petitionMedium.url,
			processing: update.photo.petitionMedium.processing,
		};
	if (update.embeddedMedia && update.embeddedMedia.thumbnailUrl)
		return {
			url: update.embeddedMedia.thumbnailUrl,
			processing: false,
		};
	return null;
}
