import { useCallback } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useCountryCode } from 'src/app/shared/hooks/l10n';

import { usePromotedTrackingData } from '../shared/promotedTrackingData';

import { trackPromotionImpression } from './api';

export type TrackingPromotionPlacement =
	| 'petition_footer'
	| 'discover_feed'
	| 'search_footer'
	| 'update_footer'
	| 'dm_responses'
	| 'psf_petitions'
	| 'browse_page'
	| 'local_page'
	| 'topics_page';

type Options = Readonly<{
	petitionId: string;
	/**
	 * if null, impression will not be tracked
	 */
	promotionToken: string | null;
	algorithm?: string;
	placement: TrackingPromotionPlacement | `${TrackingPromotionPlacement}_victory`;
}>;

export function useTrackPromotionImpression({ petitionId, promotionToken, placement, algorithm }: Options): () => void {
	const [, setPromotedData] = usePromotedTrackingData(petitionId);
	const countryCode = useCountryCode();
	const utilityContext = useUtilityContext();

	return useCallback(() => {
		// tracking impression on bot users doesn't make sense
		if (utilityContext.userAgent.isBot()) return;
		if (!promotionToken) return;

		setPromotedData({ token: promotionToken, placement, algorithm });
		void trackPromotionImpression(
			{
				promotionToken,
				countryCode,
				placement,
				algorithm,
			},
			utilityContext,
		);
	}, [setPromotedData, promotionToken, countryCode, placement, algorithm, utilityContext]);
}
