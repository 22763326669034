import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { FCM_SIGNATURE_THERMOMETER } from '@change-corgi/fcm/configs';

import { isMobile } from 'src/app/shared/utils/userAgent';

const SIGNATURE_THERMOMETER_EXPERIMENT_NAME = 'signature_thermometer_experiment_desktop';

export async function isSignatureThermometerEnabled(utilityContext: UtilityContext) {
	const fcm = await utilityContext.fcm.get({
		signatureThermometerEnabled: FCM_SIGNATURE_THERMOMETER,
	});

	if (!fcm.signatureThermometerEnabled) return false;
	if (isMobile(utilityContext)) return true;

	const experiment = utilityContext.experiments.get(SIGNATURE_THERMOMETER_EXPERIMENT_NAME);
	void experiment.treat();
	return experiment.variation === 'v1';
}
